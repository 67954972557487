import ArticleLayout from "../components/articleLayout/ArticleLayout";
import Divider from "../components/divider/Divider";
import PageTitle from "../components/contentHeadings/PageTitle";
import Quote from "../components/quote/Quote";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import { Link } from 'gatsby';

const KanJegBrukeSminke = () => {
    return(
        <ArticleLayout>
            <Seo title={"Kan jeg bruke sminke?"} />
            <PageTitle>Kan jeg bruke sminke på vippeextensions?</PageTitle>
            <Quote>
                Kort fortalt: Vannfast sminke vil løse opp vippeextensions-limet og må ikke brukes.
                Du kan bruke ikke-vannfast sminke, men vi anbefaler å la være siden det kan gå ut over
                holdbarheten og fargen til vippeextensions-ene. Sminke i resten av ansiktet går naturligvis
                bra, men sørg for å <Link to={'/rens-av-vippeextensions'}>rense vippene</Link>. Ikke ha sminke på extensions-ene når du kommer for å ta påfyll.
            </Quote>
            <Divider color={"pink"} />
            <p>Vi anbefaler å ikke bruke sminke oppå dine vippeextensions. Det er vanskelig å få vekk, vippene vil ikke være like svarte, de vil se sammenklistret ut og de vil falle fortere av. Vannfast sminke er oljebasert, og vil løse opp vippeextensions-limet. Det er mulig å bruke sminke som ikke er vannfast.</p>
            <SubHeading>Ikke ha sminke på vippene når du tar påfyll</SubHeading>
            <p>Har du sminke på vippeextensions-ene dagen du kommer for å ta påfyll risikerer du at vi må fjerne alle før vi kan sette på nye. Dette er fordi f.eks. mascara er vanskelig å fjerne, og de nye extensions-ene kommer til å klistre seg fast i restene. Det kan godt hende at de kommer til å falle raskere av enn normalt. Hvis vi må rense vippene dine for deg når du kommer til timen går det utover tiden vi har til rådighet for å sette på vippeextensions. </p>
            <SeeAvailableButton>Se ledige timer</SeeAvailableButton>
        </ArticleLayout>
    )
};

export default KanJegBrukeSminke;