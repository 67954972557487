import React from 'react';

// import styles from './quote.module.scss';

const inlineStyles = {
    padding: "1rem 2rem",
    margin: "1rem 0",
    backgroundColor: "#ccffdd"
}

const Quote = ({ children }) => {

    return(
        <div style={inlineStyles}>
            { children }
        </div>
    )
};

export default Quote;